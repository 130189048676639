// COLOR ---------------------------------------------------------------------------------------------------------------------

$primary-color: #7bb8ed;
$secondary-color: #BCA0B8;
$gold: #BD940F;
$secondary-light-color: #D0DDFF;
$black: #222222;
$abs-black: #080808;
$white: #ffffff;
$gray: rgb(241, 241, 241);
$gray-transparent: rgba(30, 30, 30, 0.6);
$white-transparent: rgba(225, 225, 225, 0.6);

// MARGIN & PADDING ----------------------------------------------------------------------------------------------------------

$small-size: .8rem;
$medium-size: 1.6rem;
$large-size: 3.2rem;
$xlarge-size: 4.8rem;


// BREAKPOINTS ----------------------------------------------------------------------------------------------------------

$break-small: 576px;
$break-medium: 768px;
$break-large: 992px;
$break-xlarge: 1200px;

// TEXT ----------------------------------------------------------------------------------------------------------

$xsmall-text: .8rem;
$small-text: 1.2rem;
$default-text: 1.6rem;
$medium-text: 1.8rem;
$large-text: 2.4rem;
$xlarge-text: 6.4rem;
$xxlarge-text: 7.2rem;


// ICON SIZE ----------------------------------------------------------------------------------------------------------

$small-icon: 1.2rem;
$default-icon: 1.6rem;
$medium-icon: 1.8rem;
$large-icon: 2.4rem;

// ELEMENT SIZE --------------------------------------------------------------------------------------------------------------

$navbar-desktop-size: 5rem;

// TRANSITION & KEYFRAME --------------------------------------------------------------------------------------------------------------

$transition: all .3s cubic-bezier(0.46, 0.03, 0.52, 0.96);
$transition-slow: all .5s cubic-bezier(0.46, 0.03, 0.52, 0.96);

@keyframes levitation {
  0% {
    transform: translate(-50%, 10px);

  }
  30% {
    transform: translate(-50%, -10px);
  }
  100% {
    transform: translate(-50%, 10px);
  }
}

@keyframes scroll {
  0% {
    transform: translate(calc((-50% + 40px)), 50%) scale(.2);
  }
  40% {
    transform: translate(calc((-50% - 40px)), 50%) scale(.2);
  }
  100% {
    transform: translate(calc((-50% + 40px)), 50%) scale(.2);
  }
}
@keyframes scroll-reverse {
  0% {
    transform: translate(calc((-50% - 20px)), -50%);

  }
  60% {
    transform: translate(calc((-50% + 20px)), -50%);
  }
  100% {
    transform: translate(calc((-50% - 20px)), -50%);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes appear-page {
  0% {
    opacity: 0;
    transform: translateY(5rem);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes appear-x {
  0% {
    opacity: 0;
    transform: translateX(200%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes appearReverse {
  0% {
    opacity: 0;
    transform: translateY(50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes image-scroll {
  0% {
    object-position: 0 100%;
  }
  50% {
    object-position: 0 0%;
  }
  100% {
    object-position: 0 100%;
  }
}








