// ----- VARIABLES -----
@import './variables';

// ----- ROULES -----

@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

.material-symbols-rounded {
  font-variation-settings:
  'FILL' 0,
  'wght' 100,
  'GRAD' 0,
  'opsz' 24
}

html, body, body * {
  box-sizing: border-box;
}

:root {
  overscroll-behavior: none;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;

  *::-webkit-scrollbar {
    display: none;
  }

  *::selection {
    color: $black;    
    background: rgba($color: $secondary-color, $alpha: .8);
  }

  *::-moz-selection {
    color: $black;    
    background: rgba($color: $secondary-color, $alpha: .8);
  }

  body {
    margin: 0;
    padding: 0;
    box-sizing: content-box;
    font-size: 1.6rem;
    color: $black;
    font-family: 'Poppins', sans-serif;
    overflow-x: hidden;

    button {
      background: transparent;
      border: none;
      outline: none;
    }

    & .reg-font {
      font-family: 'Bebas Neue', serif;
    }

    & .only-stroke {
      -webkit-text-stroke: .1px $black;
      color: transparent;
    }

    a {
      text-decoration: none;
      color: inherit;
    }

    li {
      list-style: none;
    }

    p,
    ul,
    li {
      margin: 0;
      padding: 0;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-inline-start: 0;
      margin-inline-end: 0;
    }

    .container {
      max-width: $break-xlarge;
      margin: 0 auto;
      padding: $medium-size;


      // width: 100%;
      // overflow: hidden;
      &.small {
        max-width: $break-medium;
      }
      &.no-padding {
        padding: 0;
      }
    }

    .debug {
      border: 1px solid red;
      min-height: 50px;
    }
  }
}

.bg-white {
  background-color: white;
}


@media (max-width: $break-small) {
  html {
    font-size: 55%;
  }
  .container {
    &.no-padding-xs {
      padding: 0;
    }
  }
}

// ----- SETTINGS -----
.page {
    padding-top: 9rem;
    @media (max-width: $break-medium) {
        padding-top: 12rem;
    }
    .inner-page {
        background-color: $white-transparent;
        border-radius: 3rem;
        backdrop-filter: blur(15px);
        margin-bottom: 2.4rem;
        padding: 2.4rem;
        box-shadow: 0px 0px 2px $gray-transparent;
        &.black {
          background-color: $gray-transparent;
          backdrop-filter: blur(10px) saturate(180%);
          box-shadow: 0px 0px 2px $white-transparent;
        }
    }
}

// ----- PAGES -----
@import './pages/home';


// ----- COMPONENTS -----
@import './components/header';
@import './components/footer';
@import './components/image-and-text';
@import './components/double-image';
@import './components/hero';
@import './components/value-table';
@import './components/contact-form';

