.double-image-component {
    display: flex;
    width: 100%;
    gap: 1.2rem;
    margin-bottom: 1.2rem;
    @media (max-width: $break-medium) {
        flex-direction: column;
    }

    &.invert {
        flex-direction: row-reverse;
        @media (max-width: $break-medium) {
            flex-direction: column-reverse;
        }
    }

    .img-wrapper {
        position: relative;
        img {
            flex: 1;
            overflow: hidden;
            border-radius: 3rem;
            box-shadow: 0px 0px 5px $gray-transparent;
            width: 100%;
            height: auto;
            object-fit: cover;
            min-height: 50vh;
            max-height: 90rem;
        }
        .text-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: $white;
            background-image: linear-gradient(transparent, 20%, $black);
            padding-bottom: 4.2rem;
            border-radius: 0 0 3rem 3rem;

            .hero-logo {
                height: 9rem;
                width: auto;
                flex: none;
                min-height: initial;
            }

            p {
                font-size: 4.8rem;
                text-align: center;
                padding: 2.4rem;
            }
            .cta {
                border: 1px solid $white;
                border-radius: 3rem;
                padding: .4rem 1.8rem;
                font-weight: 300;
                span {
                    opacity: 1;
                }
            }
        }
    }
}