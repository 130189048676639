.header {
    position: fixed;
    top: 0;
    left: 0;
    width: calc(100% - 2.4rem);
    height: auto;
    margin: 2.4rem 1.2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 5rem;
    padding: 1.2rem 2.4rem;
    background-color: $gray-transparent;
    backdrop-filter: blur(6px) saturate(180%);
    z-index: 9;
    box-shadow: 0px 0px 2px $white-transparent;
    transition: $transition-slow;

    &.hide {
        transform: translateY(-200%);
    }

    .logo {
        margin: 0;
        font-size: 1.8rem;
        font-weight: 500;
        a {
            display: flex;
            color: $white;
            gap: .3rem;
            line-height: normal;
            span {
                display: block;
                font-weight: 100;
                transform: translateX(-12px);
                transition: $transition;
            }
            &:hover {
                span {
                    transform: translateX(0);
                }
            }
        }
    }
    .nav-list {
        display: flex;
        align-items: baseline;
        gap: 1.8rem;
        .nav-item {
            transition: $transition;
            transform: scale(.95);
            font-weight: 400;
            a {
                color: $white;
            }

            &:hover {
                transform: scale(1);
            }
            &.evidence {
                display: flex;
                gap: 0.8rem;
                border: 1px solid $white;
                font-weight: 300;
                // background-color: $primary-color;
                border-radius: 2rem;
                padding: 0.4rem 1.8rem;
                color: $white;
                
            }
        }
    }
    .hamburger-button {
        display: none;
        color: $white;
        align-items: center;
        justify-content: center;
        transition: $transition;

        &.open {
            transform: rotate(-90deg);
        }

        span {
            font-size: 3.6rem;
        }
    }
}

.mobile-menu {
    display: none;
    position: fixed;
    top: 10rem;
    // bottom: 0;
    left: 0;
    width: calc(100% - 2.4rem);
    margin: 1.2rem;
    padding: 1.2rem 2.4rem;
    background-color: $gray-transparent;
    backdrop-filter: blur(10px) saturate(180%);
    z-index: 9;
    box-shadow: 0px 0px 2px $white-transparent;
    transition: $transition-slow;
    border-radius: 3rem;
    &.hidden {
        opacity: 0;
        transform: translateY(5rem);
        visibility: hidden;

    }
    .nav-list {
        width: 100%;
        color: $white;
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        padding: 4.8rem 0;
        .nav-item {
            display: flex;
            gap: 1.6rem;
            font-size: 2.4rem;
            span {
                width: 2.4rem;
                height: 2.4rem;
                align-self: center;
            }
        }
    }
}

@media (max-width: $break-medium) {
    .header {
        height: 8rem;
        .nav-list {
            display: none;
        }
        .logo {
            a {
                font-size: 2.4rem;
                span {
                    display: block;
                    font-weight: 100;
                    transform: translateX(0);
                    transition: $transition;
                }
            }
        }
        .hamburger-button {
            display: flex;
        } 
    }
    .mobile-menu {
        display: flex;
    }
}