.image-and-text-component {
    display: flex;
    width: 100%;
    gap: 3.6rem;
    margin: 1.2rem 0;
    @media (max-width: $break-medium) {
        flex-direction: column-reverse;
    }

    &.invert {
        flex-direction: row-reverse;
        @media (max-width: $break-medium) {
            flex-direction: column-reverse;
        }
        .text-wrapper {
            justify-content: flex-start;
        }
        img {
            @media (min-width: $break-medium) {
                margin-top: 10rem;
            }
            margin-bottom: 0;
        }
    }

    img, .text-wrapper {
        flex: 1;
        overflow: hidden;
    }
    img {
        border-radius: 3rem;
        @media (min-width: $break-medium) {
            margin-bottom: 10rem;
        }
        box-shadow: 0px 0px 5px $gray-transparent;
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    .text-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .eyebrow {
            font-size: 1.4rem;
            font-weight: 200;
            letter-spacing: 5px;
            margin-bottom: .8rem;
            text-transform: uppercase;
        }
        h3 {
            font-size: 6.4rem;
            margin: 0;
            margin-bottom: 4rem;
            width: fit-content;
            line-height: 1;
            color: $gray-transparent;
        }
        .cta {
            margin-top: 2.4rem;
        }
    }
}

.cta {
    display: flex;
    text-transform: uppercase;
    gap: .4rem;
    
    button, a {
        text-transform: uppercase;
    }

    &.secondary {
        border: none !important;
        i {
            opacity: 1;
        }

        &:hover {
            i {
                transform: translateX(0);
            }
        }
    }

    &.anchor {
        i {
            transform: translateY(-1rem);
            @media (max-width: $break-medium) {
                transform: translateX(0);
            }
        }
        &:hover {
            i {
                transform: translateY(0);
                opacity: 1;
            }
        }
    }

    i {
        height: 2.4rem;
        width: 2.4rem;
        transition: $transition;
        opacity: 0;
        @media (max-width: $break-medium) {
            opacity: 1;
        }
    }
    &:hover {
        i {
            transform: translateX(1rem);
            opacity: 1;
        }
    }
}

.to-do-list-component {
    display: flex;
    flex-direction: column;
    // align-items: center;
    h3 {
        font-size: 6.4rem;
        margin: 0;
        margin-bottom: 4rem;
        width: fit-content;
        line-height: 1;
        color: $gray-transparent;
        text-transform: uppercase;
    }
    .list-wrapper {
        width: 100%;
        columns: 2;
        margin-bottom: 2.4rem;
        @media (max-width: $break-medium) {
            columns: 1;
        }
        li {
            display: flex;
            align-items: flex-start;
            gap: .8rem;
            margin-bottom: .8rem;
        }
    }
}

.images-wall-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 3;
    h3 {
        font-size: 6.4rem;
        margin: 0;
        margin: 2.4rem 0 4rem 0;
        width: fit-content;
        line-height: 1;
        color: $gray-transparent;
        text-transform: uppercase;
        text-align: center;
    }
    & div {
        width: 100%;
    }
    img {
        object-fit: cover;
        transition: all .2s ease-in-out;
        overflow: hidden;
        &:hover {
            transform: scale(1.05);
            z-index: 5;
            @media (max-width: $break-medium) {
                transform: none;
            }
        }
    }
}