.footer {
    width: 100%;
    height: auto;
    margin: 2.4rem 0;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    border-radius: 5rem;
    padding: 9rem 2.4rem;
    background-color: $gray-transparent;
    backdrop-filter: blur(6px) saturate(180%);
    box-shadow: 0px 0px 2px $white-transparent;
    gap: 15vw;
    
    .logo {
        margin: 0;
        font-size: 1.8rem;
        font-weight: 500;
        a {
            display: flex;
            color: $white;
            gap: .3rem;
            line-height: normal;
            span {
                display: block;
                font-weight: 100;
                transform: translateX(-12px);
                transition: $transition;
            }
            &:hover {
                span {
                    transform: translateX(0);
                }
            }
        }
    }
    .info-grid {
        display: grid;
        flex: 1;
        grid-template-columns: repeat(3, 1fr);
        gap: 2.4rem 0.4rem;
        .info-box {
            color: $white;
            .title-box {
                font-size: 1.8rem;
                font-weight: 500;
                margin-bottom: .8rem;
            }
            p {
                font-size: 1.2rem;
                font-weight: 300;
                a {
                    &:hover {
                        text-decoration: underline;
                    }
                }
            } 
        }
    }
}

@media (max-width: $break-medium) {
    .footer {
        flex-direction: column;
        align-items: center;
        gap: 4.8rem;
        padding: 4.2rem 2.4rem;
        .logo {
            a {
                font-size: 2.8rem;
                span {
                    display: block;
                    font-weight: 100;
                    transform: translateX(0);
                    transition: $transition;
                }
            }
        }
        .info-grid {
            grid-template-columns: 1fr;
        } 
    }
}