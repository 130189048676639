.hero-component {
    display: flex;
    width: 100%;
    margin-bottom: 1.2rem;

    .img-wrapper {
        position: relative;
        overflow: hidden;
        .img-cover {
            flex: 1;
            overflow: hidden;
            border-radius: 3rem;
            box-shadow: 0px 0px 5px $gray-transparent;
            width: 100%;
            height: auto;
            object-fit: cover;
            aspect-ratio: 16/8;
            @media (max-width: $break-medium) {
                aspect-ratio: 9/16;
            }
        }
        .text-wrapper {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: $white;
            background-image: linear-gradient(transparent, 20%, $black);
            padding-bottom: 4.2rem;
            border-radius: 3rem;

            .hero-logo {
                height: 9rem;
                width: auto;
            }

            p {
                font-size: 7.2rem;
                text-align: center;
                padding: 2.4rem;
            }
        }
    }
}