.value-table-component {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: .8rem;
    @media (max-width: $break-medium) {   
        grid-template-columns: repeat(2, 1fr);
    }
    .text-box {
        background-color: rgba($color: $white, $alpha: 0.7);
        border-radius: 3rem;
        padding: 3.6rem 2.4rem;
        box-shadow: 0px 0px 2px $gray-transparent;
        grid-column-start: 1;
        grid-column-end: 3;
        height: fit-content;
        &.small {
            background-color: transparent;
            color: $white;
            box-shadow: none;
            grid-column-end: 5;
            @media (max-width: $break-medium) {   
                grid-column-end: 3;
            }
        }
        h3, p {
            text-align: center;
        }
        h3 {
            font-size: 3.2rem;
            margin: 0;
            margin-bottom: 1.8rem;
            text-transform: uppercase;
            font-weight: 600;
            line-height: 1.2;
            letter-spacing: -1px;
        }
    }
    .value-item {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        color: white;
        padding: 1.2rem;
        i {
            font-size: 9rem;
            margin-bottom: 1.2rem;
        }
        .value-title {
            text-align: center;
            font-weight: 500;
            margin-bottom: 0.8rem;
        }
        .value-text {
            text-align: center;
            font-size: 1.2rem;
            min-height: 6rem;
            @media (max-width: $break-medium) {   
                min-height: 7rem;
            }
        }
    }
}